import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent } from "./index.js";
import { PROMOTIONAL_PLANNING_PROMO_CUSTOMER } from "@kraftheinz/common";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", { attrs: { "custom-url": "" + _vm.customApiUrl + _vm.customerId, "entity-id": _vm.customerId, "is-fetch-entity-by-id": true, "last-modified-by-name": true, "title": "Edit Customer", "key-receive-event": _vm.keyReceiveEvent }, on: { "change": _vm.onChangeControl } }, [_c("text-input", { key: "code", attrs: { "label": "National Account Code", "disabled": true, "placeholder": "National Account Code", "rules": "required|alpha_dash", "span": 12 } }), _c("select-input", { key: "region", attrs: { "label": "Region", "reference": "promotion-planning.common.regions", "placeholder": "Region", "on-select-change": _vm.onRegionChange, "source": "code", "source-label": "description", "rules": "required", "span": 12 } }), _c("text-input", { key: "description", attrs: { "label": "National Account", "placeholder": "National Account", "rules": "required", "max-length": 255, "span": 24 } }), _c("text-input", { key: "keyAccountCode", attrs: { "label": "Key Account Code", "disabled": true, "placeholder": "Key Account Code", "rules": "required|alpha_dash", "max-length": 10, "span": 12 } }), _c("text-input", { key: "keyAccount", attrs: { "label": "Key Account", "placeholder": "Key Account", "rules": "required|not_same:" + _vm.distributionCenter + "," + _vm.keyAccount + ",Distribution Center", "max-length": 255, "span": 24 } }), _c("select-input", { key: "distributionCenterId", attrs: { "label": "Distribution Center", "placeholder": "Distribution Center", "data-source": _vm.distributionCenterList, "loading": _vm.loading, "all-in-value": true, "on-select-change": _vm.onDistributionCenterChange, "rules": "not_same:" + _vm.distributionCenter + "," + _vm.keyAccount + ",Key Account", "source": "id", "source-label": function(val) {
    return _vm.generateLabel(val, ["region", "keyAccountCode", "keyAccount"]);
  }, "span": 24 } })], 1);
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditCustomer",
  inject: ["crud"],
  mixins: [PromoUtils],
  data() {
    const { id } = this.$route.params;
    return {
      customerId: id,
      customApiUrl: `${apiUrl$1}/promotion-planning/customers/`,
      keyAccount: "",
      distributionCenterList: [],
      distributionCenter: "",
      keyReceiveEvent: ["keyAccount"],
      loading: false,
      apiUrl: apiUrl$1,
      entity: {}
    };
  },
  async mounted() {
    await this.crud.fetchEntity(), this.entity = this.crud.getEntity();
    this.keyAccount = this.entity.keyAccount;
    this.distributionCenter = this.entity.distributionCenter;
    this.getDistributionCenterList(this.entity.region);
  },
  methods: {
    onRegionChange(region) {
      this.getDistributionCenterList(region);
    },
    async getDistributionCenterList(region) {
      this.loading = true;
      const res = await this.axios.get(`${this.apiUrl}/promotion-planning/common/customers/${region}?None=true`);
      if (res && res.data) {
        this.distributionCenterList = res.data.results;
        this.loading = false;
      }
    },
    onChangeControl(value) {
      Object.keys(value).forEach((key) => {
        if (key === "keyAccount") {
          this.keyAccount = value.keyAccount;
        }
      });
    },
    onDistributionCenterChange(value) {
      this.crud.updateEntity("editForm", {
        resourceKey: "distributionCenterId",
        value: value.id
      });
      this.distributionCenter = value.keyAccount;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditCustomer = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-customer" }, [_c("resource", { attrs: { "name": "promotion-planning.common.regions", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.customers", "api-url": _vm.apiUrl, "redirect-route": "/promotion-planning/customers", "page": _vm.page } }, [_c("edit-customer")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditCustomer
  },
  data() {
    return {
      apiUrl,
      page: PROMOTIONAL_PLANNING_PROMO_CUSTOMER
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
